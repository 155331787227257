import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>HSBC UK’s 90% LTV back on sale</h1>
    <p>The 90% LTV mortgage rates include a two year fix rate deal with £999 fee at 3.69% (3.99% no fee) and a 5 year fixed rate with £999 fee at 3.84%, plus a two year tracker rate of 3.79% (with £999 fee).</p>
    <p>The bank has also reduced mortgage rates on 17 mortgages with immediate effect, with savings of up to 0.20%. Reductions to mortgage rates include:</p>
    <ul>
      <li>2 year fixed rate at 60% LTV (no fee) cut by 0.15% to 1.64%, with the £999 fee equivalent reduced by 0.10% to 1.24%.</li>
      <li>0.20% reduced from 5 year fixed rate Feesaver mortgages at 60% LTV to 1.79%, 70% LTV to 1.99% and 75% LTV down to 1.99%.</li>
      <li>5 year fixed rates at 80% reduced by 0.15% to 2.29% (£999 fee) and 2.59% (no fee).</li>
    </ul>
    <p>Michelle Andrews, HSBC UK’s Head of Buying A Home, said: “Home-ownership dreams may have seemed even further away for many first time buyers of late, but with more providers returning to 90% LTV mortgages, they may be closer to becoming a reality, which is great news.</p>
    <p>“Lower rates across other LTVs is also great news, and could well provide those looking to remortgage, switch rates or those on an SVR with some important savings on their current monthly outlay.”</p>
    <p>All mortgages are available direct from HSBC UK and through its broker partners.</p>
  </NewsArticle>
)

export default Article
